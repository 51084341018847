import React from 'react'
import { FaAdversal } from 'react-icons/fa'
// import { FaMoneyBillTransfer } from 'react-icons/fa6'
import { IoIosCopy } from 'react-icons/io'
import { MdSupportAgent } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
// import Form from './form'


function Ticketpage() {


    const form = useNavigate()
    const Gotoform = (value) =>{
        form("/Form/"+value)
}


    return (
        <div className='ticketpage'>
            <div className="ticket-box">
            <h2>what can we help with?</h2>
            <div className='mainbox'>
                <div className='mainbox1' onClick={()=>Gotoform("IT Support")}>
                    <div className='iconbox'> <IoIosCopy style={{marginLeft:'20px', width:'35px',height:"35px"}}/></div>
                    <h4>IT Support</h4>
                </div>
                <div className='mainbox1' onClick={()=>Gotoform("Nutrition Support")}><div className='iconbox'><MdSupportAgent style={{marginLeft:'20px', width:'35px',height:"35px"}}/></div>
                    <h4>Nutrition Support</h4></div>
                <div className='mainbox1' onClick={()=>Gotoform("Account Management Support")}><div className='iconbox'><FaAdversal style={{marginLeft:'20px', width:'35px',height:"35px"}}/></div>
                    <h4>Account Management Support</h4></div>
                <div className='mainbox1' style={{backgroundColor:'#6A4FA0' ,
                    color:"white",
                    
                }} onClick={()=>Gotoform("Other")}>
                    <h4>Other</h4></div>
            </div>
            </div>
        </div>
    )
}

export default Ticketpage
