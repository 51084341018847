import { message } from 'antd'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { FaTimes } from 'react-icons/fa';

function Form() {

  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [priority, setpriority] = useState("low")
  const [subject, setsubject] = useState("")
  const [messagee, setmessagee] = useState("")
  const [attachment, setattachment] = useState()
  const { id } = useParams();
  const navigate = useNavigate()


  const senddata = async (e) => {
    e.preventDefault()
    const hide = message.loading("Action in progress", 0)

    let fileurl
    if (attachment) {
      const imgRef = ref(imageDb, `ticketfiles/${attachment.name}`)
      await uploadBytes(imgRef, attachment)
      fileurl = await getDownloadURL(imgRef);


    } else {
      fileurl = "no file"
    }



    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("name", name);
    urlencoded.append("email", email);

    urlencoded.append("subject", subject);
    urlencoded.append("Message", messagee);
    urlencoded.append("category", id);
    urlencoded.append("attachment", fileurl);


    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    await fetch("http://localhost:1337/addtickets", requestOptions)
      .then((response) => response.text())
      .then((result) => 
        {
          // navigate("/")


        }
    
    )
      .catch((error) => console.error(error));


    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Ticket Submitted");
    }, );
  }


  return (
    <div className='formpage'>
      <form action="" onSubmit={senddata}>
        <div className="cancel">
          <a href="#" onClick={() => navigate("/ticket")}> <FaTimes size={24} /></a>
        </div>
        <h2 style={{ textAlign: 'center' }}>{id} Form</h2>
        <label htmlFor="">Name:*</label>
        <input type="username" required value={name} onChange={(e) => setname(e.target.value)} />

        <label htmlFor="">Email:*</label>
        <input type="email" required value={email} onChange={(e) => setemail(e.target.value)} />


        <label htmlFor="">subject:*</label>
        <input type="text" required value={subject} onChange={(e) => setsubject(e.target.value)} />

        <label htmlFor="">Message*</label>
        <textarea name="" id="" required value={messagee} onChange={(e) => setmessagee(e.target.value)} />

        <label htmlFor="">attachment:</label>
        <input type="file" className='file' onChange={(e) => setattachment(e.target.files[0])} />

        <input type='submit' />
      </form>
    </div>
  )
}

export default Form
