import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'


function Viewtickets() {


  const token = useNavigate()
  const Gotonext = () => {
    token("/tracking/" + ticketid)
  }

  const [ticketid, setticketid] = useState("")


  return (
    <div className='viewtcketspage'>
      <div className='formpage'  style={{height:'80vh'}}>


        <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} onSubmit={Gotonext}>
          <label>Ticket tracking id:*</label>
          <input type='text' required value={ticketid} onChange={(e) => setticketid(e.target.value)} />
          <input type='submit' />
          <a href=''>Forget tracking ID?</a>
        </form>
      
      </div>
    </div>
  )
}

export default Viewtickets
