import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import { FaRegUserCircle } from 'react-icons/fa';
import { RiSendPlaneFill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom'

function Tokenpage() {
  const navigate = useNavigate()
  const { id } = useParams();
  const [tickets, settickets] = useState([])
  const [reply, setreply] = useState("")

  useEffect(() => {
    const intervalId = setInterval(() => {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("ticketID", id);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallticketsbyticketID", requestOptions)
        .then(response => response.json())
        .then(result => settickets(result))
    }, 1000); // Runs every 1 second
    return () => clearInterval(intervalId);
  }, [])

  return (
    <div>
      <div className="toparticlesfirstpagemaindiv">
        <div className='toparticlesfirstpageleft'>




          {tickets.length !== 0 && <>

            <div className='toparticlesfirstpagelefthead'>
              <h3> Subject: {tickets.subject}</h3>
              <p style={{ display: 'flex', textAlign: 'left', flexDirection: "column", margin: '0' }}>
                Message: <br />
                <span dangerouslySetInnerHTML={{ __html: tickets.Message.replace(/\n/g, "<br />") }} />
              </p>
            </div>




            {/* <div className='header' style={{ marginTop: '50px' }} >
              <h2>Replies </h2>
            </div> */}
            <div className='messageboxmain'>
              {tickets.reply.map((value) => {
                return (<>

                  {value.replyby === "admin" ? (<>
                    <div className='massege'>

                      <FaRegUserCircle style={{width:'50px',height:'50%',marginRight:'20px',color:"#6A4FA0"}}/>
                      <div class="sharp-corner-box">
                        <p style={{ display: 'flex', textAlign: 'left' }}>

                          <span dangerouslySetInnerHTML={{ __html: value.contant.replace(/\n/g, "<br />") }} />
                        </p>

                          <div style={{ color: "gray" }}>{value.date}</div>
                      </div>
                      {/* <div style={{ color: "gray" }}>{value.date}</div> */}
                    </div> 
                    
                    
                    </>) : (<>
                      <div style={{ display: 'flex', justifyContent: "right", width: '100%', }}>
                       
                        <div class="sharp-corner-box2">
                          <p style={{ display: 'flex', textAlign: 'left' }}>

                            <span dangerouslySetInnerHTML={{ __html: value.contant.replace(/\n/g, "<br />") }} />
                          </p>
                          <div style={{ color: "gray" }}>{value.date}</div>
                        </div>
                        <FaRegUserCircle style={{width:'50px',height:'50%',marginLeft:'20px',color:"#CACEF7"}}/>
                     
                      </div>
                    </>)}
                </>)
              })}

            </div>

            {tickets.status === "open" ? (<>
              <div style={{ marginTop: '50px' }}></div>
              {/* <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', marginl: '0px', padding: '0px' }}>Reply : </h4> */}
              <form className='replyform' onSubmit={async (e) => {
                e.preventDefault()
                const hide = message.loading("Action in progress", 0)

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("_id", tickets._id);
                urlencoded.append("contant", reply);

                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: urlencoded,
                  redirect: 'follow'
                };


                await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/replybyuser", requestOptions)
                  .then(response => response.json())
                  .then(result => {
                    settickets(result)
                    setreply("")
                  })
                  .catch(error => console.log('error', error));


                await setTimeout(() => {
                  hide(); // Call hide to stop the loading message
                  message.success("Action completed successfully");
                }, 2000);

              }} > 
              
               <textarea value={reply} onChange={(e) => setreply(e.target.value)} required  placeholder='Type your Replay' />
                
                 <button className='button'><RiSendPlaneFill style={{width:'40px',height:'40px',color:'#6A4FA0'}}/></button>
                 
            </form>
            </>) : (<>

              <p style={{ color: 'gray' }}>Ticket is Closed by Admin</p>
            </>)}




          </>}
        </div>
        {tickets.length !== 0 && <>

        <div className='toparticlesfirstpageright'>
          <div className="articlesdetailbox">
            <h4>Ticket Details</h4>

            <div className='articlesdetailboxinside'>
              <span><p>Ticket ID</p> <p>: {tickets.ticketID}</p></span>
              <span><p>category</p> <p>: {tickets.category}</p></span>


              <span><p>Date </p><p>: {tickets.timestamp.split("T")[0]}</p></span>
              <span><p>status</p> <p>: {tickets.status}</p></span>
              {/* <span><p>rated(votes):</p> <p>
              &#9733;
              &#9733;
              &#9733;
              &#9734;
              &#9734;(1,251)</p></span> */}
              <div className='backbutton'>

                <a href="" >Go back</a>
              </div>
            </div>
          </div>




        </div>
        </>}
      </div>
    </div>
  )
}

export default Tokenpage
